type TimeUnit = 'milliseconds' | 'seconds' | 'minutes' | 'hours' | 'days'

const formatDate = (date: Date | string | undefined | null, options?: Intl.DateTimeFormatOptions) => {
	if (!date) { return '' }
	return new Date(date).toLocaleDateString('en-US', options)
}

const formatUnixTimestamp = (timestamp: string, options?: Intl.DateTimeFormatOptions) => {
	return new Date(parseInt(timestamp) * 1000).toLocaleDateString('en-US', options)
}

const formatTimerForDisplay = (timer: number | undefined, input: TimeUnit = 'seconds', output: TimeUnit = 'days'): string => {
	if (timer === undefined) { return 'unknown' }

	// Conversion factors in milliseconds
	const conversionMap: Record<TimeUnit, number> = {
		milliseconds: 1,
		seconds: 1000,
		minutes: 1000 * 60,
		hours: 1000 * 60 * 60,
		days: 1000 * 60 * 60 * 24
	}

	// Normalize to milliseconds, then convert to the desired output unit
	const normalizedTime = timer * conversionMap[input] // Convert input to milliseconds
	const convertedTime = normalizedTime / conversionMap[output] // Convert milliseconds to output unit

	return `${Math.floor(convertedTime)} ${output}`
}

const MD: Intl.DateTimeFormatOptions = {
	month: 'numeric',
	day: 'numeric'
}

const MDY2: Intl.DateTimeFormatOptions = {
	year: '2-digit',
	month: 'numeric',
	day: 'numeric'
}

const MDY2T: Intl.DateTimeFormatOptions = {
	year: '2-digit',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	timeZoneName: 'short'
}

const MDY4TTZ: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	timeZoneName: 'short'
}

const MLDY4TTZ: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'long',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
	timeZoneName: 'short'
}

const MSD: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: 'numeric'
}

const MSDY: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: 'numeric',
	year: 'numeric'
}

const formatDateForInput = (timestamp: string | Date) => {
	return new Date(timestamp).toISOString().split('T')[0]
}

const formatIsoStringWithTimeZoneOffset = (dateString: string) => {
	const timeZoneOffset = new Date().getTimezoneOffset() * 60000
	const date = new Date(dateString).getTime() + timeZoneOffset
	return new Date(date)?.toISOString()
}

export { formatDate, formatUnixTimestamp, MD, MDY2, MSD, MDY2T, MSDY, MDY4TTZ, MLDY4TTZ, formatDateForInput, formatIsoStringWithTimeZoneOffset, formatTimerForDisplay }

export type { TimeUnit }
